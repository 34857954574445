/**
 * Gallery
 *
 * 01. Carousel
 * 02. Comparison Slide
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';


    /**
     * Carousel
     *
     * @requires plugins/jquery.slick.js
     */
    $('.js-carousel-beforeafter').slick({
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        appendDots: '.js-carousel-dots',
        fade: false,
        draggable: false,
        adaptiveHeight: true
    });


    /**
     * Comparison Slide
     *
     * @link https://github.com/CodyHouse/image-comparison-slider
     *
     * @requires plugins/jquery.mobile.custom.js
     */
    var dragging = false,
        resizing = false;

    //cache jQuery objects
    var imageComparisonContainers = $('.compare').addClass('is-visible');

    //make the .compare__handle element draggable and modify .compare__resize width according to its position
    imageComparisonContainers.each(function () {
        var actual = $(this);
        drags(actual.find('.compare__handle'), actual.find('.compare__resize'), actual, actual.find('.compare__label[data-type="after"]'), actual.find('.compare__label[data-type="before"]'));
    });

    //upadate images label visibility
    $(window).on('resize', function () {
        if (!resizing) {
            resizing = true;

            if (window.requestAnimationFrame) {
                requestAnimationFrame(function () {
                    checkLabel(imageComparisonContainers);
                });
            } else {
                setTimeout(function () {
                    checkLabel(imageComparisonContainers);
                }, 100);
            }
        }
    });

    function checkLabel(container) {
        container.each(function () {
            var actual = $(this);
            updateLabel(actual.find('.compare__label[data-type="before"]'), actual.find('.compare__resize'), 'left');
            updateLabel(actual.find('.compare__label[data-type="after"]'), actual.find('.compare__resize'), 'right');
        });

        resizing = false;
    }

    //draggable funtionality - credits to http://css-tricks.com/snippets/jquery/draggable-without-jquery-ui/
    function drags(dragElement, resizeElement, container, labelContainer, labelResizeElement) {
        dragElement.on("mousedown vmousedown", function (e) {
            dragElement.addClass('draggable');
            resizeElement.addClass('resizable');

            var dragWidth = dragElement.outerWidth(),
                xPosition = dragElement.offset().left + dragWidth - e.pageX,
                containerOffset = container.offset().left,
                containerWidth = container.outerWidth(),
                minLeft = containerOffset + 10,
                maxLeft = containerOffset + containerWidth - dragWidth - 10;

            dragElement.parents().on("mousemove vmousemove", function (e) {
                if (!dragging) {
                    dragging = true;
                    ( !window.requestAnimationFrame )
                        ? setTimeout(function () {
                            animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement);
                        }, 100)
                        : requestAnimationFrame(function () {
                            animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement);
                        });
                }
            }).on("mouseup vmouseup", function (e) {
                dragElement.removeClass('draggable');
                resizeElement.removeClass('resizable');
            });
            e.preventDefault();
        }).on("mouseup vmouseup", function (e) {
            dragElement.removeClass('draggable');
            resizeElement.removeClass('resizable');
        });
    }

    function animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement) {
        var leftValue = e.pageX + xPosition - dragWidth;
        //constrain the draggable element to move inside his container
        if (leftValue < minLeft) {
            leftValue = minLeft;
        } else if (leftValue > maxLeft) {
            leftValue = maxLeft;
        }

        var widthValue = (leftValue + dragWidth / 2 - containerOffset) * 100 / containerWidth + '%';

        $('.draggable').css('left', widthValue).on("mouseup vmouseup", function () {
            $(this).removeClass('draggable');
            resizeElement.removeClass('resizable');
        });

        $('.resizable').css('width', widthValue);

        updateLabel(labelResizeElement, resizeElement, 'left');
        updateLabel(labelContainer, resizeElement, 'right');
        dragging = false;
    }

    function updateLabel(label, resizeElement, position) {
        if (position == 'left') {
            ( label.offset().left + label.outerWidth() < resizeElement.offset().left + resizeElement.outerWidth() ) ? label.removeClass('is-hidden') : label.addClass('is-hidden');
        } else {
            ( label.offset().left > resizeElement.offset().left + resizeElement.outerWidth() ) ? label.removeClass('is-hidden') : label.addClass('is-hidden');
        }
    }

}(jQuery));
