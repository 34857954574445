/**
 * Home
 *
 * 01. Hero Carousel
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

    /**
     * Hero Carousel
     *
     * @requires plugins/jquery.slick.js
     */
    $('.js-hero').slick({
        autoplay: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 580,
                settings: {
                    dots: true
                }
            }
        ]
    });

}(jQuery));