/**
 * Contacts
 *
 * 01. Google Maps
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

    /**
     * Google Maps
     *
     * @summary Mappa personalizzata con https://snazzymaps.com/
     * @requires plugins/jquery.googlemaps.js
     */
    var maps = $('.js-gmap');

    if (!maps.length) {
        return;
    }

    window.onFirstUserInteraction(function(){
        var d = document,
            t = 'script';

        function l(u) {
            var j = d.createElement(t),
                s = d.getElementsByTagName(t)[0];

            j.src = u;
            j.async = 1;
            s.parentNode.insertBefore(j, s);
        }

        l('//maps.googleapis.com/maps/api/js?key=AIzaSyAO4ycwmVie3JhnpJx7-_b_kg629w_g7ZU&v=3.exp&callback=initialize');
    });

    window.initialize = function () {
        maps.googlemaps({
            style: [{"featureType":"all","elementType":"geometry.fill","stylers":[{"weight":"2.00"}]},{"featureType":"all","elementType":"geometry.stroke","stylers":[{"color":"#9c9c9c"}]},{"featureType":"all","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape.man_made","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#eeeeee"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#7b7b7b"}]},{"featureType":"road","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#c8d7d4"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#070707"}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]}]
        });
    };

}(jQuery));
