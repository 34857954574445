/**
 * Catalog
 *
 * 01. Carousel
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

    /**
     * Carousel
     *
     * @requires plugins/jquery.slick.js
     */
    var arrowleft = '<button type="button" class="slick-prev"><svg title="Prev"><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--arrow-left"></use></svg></button>',
        arrowright = '<button type="button" class="slick-next"><svg title="Next"><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--arrow-right"></use></svg></button>';

    $('.js-carousel-catalog').slick({
        infinite: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        arrows: true,
        dots: false,
        variableWidth: true,
        prevArrow: arrowleft,
        nextArrow: arrowright
    });

}(jQuery));