/**
 * Typography
 * 
 * @author Matteo Casonato
 * @required libraries/fontfaceobserver.js
 */

(function (w) {
    if (w.document.documentElement.className.indexOf("fonts-loaded") > -1) {
        return;
    }

    var font1 = new w.FontFaceObserver("Roboto", {
            weight: 300
        }),
        font2 = new w.FontFaceObserver("Roboto", {
            weight: 400
        }),
        font3 = new w.FontFaceObserver("Roboto", {
            weight: 500
        });

    w.Promise
        .all([
            font1.check(),
            font2.check(),
            font3.check()
        ])
        .then(function() {
            w.document.documentElement.className += " fonts-loaded";
        }, function() {
            w.document.documentElement.className += " fonts-unavailable";
        });
}(this));