/**
 * Global
 *
 * 01. Pongho Ajax Form
 * 02. SVG
 * 03. Cookie iFrame
 * 04. Animation
 * 05. Scrollbar
 * 06. Lightboxs
 *
 * @global jQuery
 * @author Matteo Casonato
 * @author Daniele De Nobili
 */


/**
 * Pongho Ajax Form
 *
 * @requires plugins/pongho-form.js
 */
(function ($) {
	'use strict';

	$('.js-ajaxform').ponghoForm({
		wrapperClass: 'ajax-form-wrapper',
		onSubmit: function (data, form) {
			if (window.ga) {
				// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
				window.ga(
					'send',
					{
						'hitType': 'event', // Required.
						'eventCategory': 'form', // Required.
						'eventAction': 'submit', // Required.
						'eventLabel': form.data('ga-label') || form.prop('name'),
						'page': window.location.pathname
					}
				);
			}
		}
	});

}(jQuery));


/**
 * SVG
 *
 * @requires libraries/svg4everybody.js
 * @requires ie/svg4everybody.legacy.js
 */
(function ($) {
	'use strict';

	svg4everybody();

}(jQuery));


/**
 * Cookie iFrame
 */
(function ($) {
	'use strict';

	window.onFirstUserInteraction(function() {
		$('iframe').attr('src', function () {
			return $(this).data('src');
		});
	});

}(jQuery));


/**
 * Lightboxs
 *
 * @requires plugins/jquery.chocolat.js
 */
(function ($) {
    'use strict';

    $('.js-lightbox').Chocolat({
        imageSelector: '.js-image',
        enableZoom: false
	});

}(jQuery));